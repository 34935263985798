<template>
  <v-row class="providers">
    <v-col cols="12" class="pa-0">
      <div class="title justify-space-between">
        <div class="white--text text-uppercase text-sm-h5 text-h6 font-weight-bold">providers</div>
        <div class="d-flex title-input">
          <v-text-field
            v-model="search"
            placeholder="Search by name or login"
            prepend-inner-icon="mdi-magnify"
            :append-icon="search ? 'mdi-close' : ''"
            @click:append="clearSearch"
            outlined
            dense
            required
            color="input"
            @change="(page = 1), getData()"
          >
          </v-text-field>
          <v-btn @click="getData" elevation="0" class="white--text ml-5" width="120" height="48" color="secondary">
            <v-icon left>mdi-refresh</v-icon>Refresh
          </v-btn>
          <v-btn to="/provider-new" elevation="0" class="white--text ml-5" width="184" height="48" color="primary">Add Provider</v-btn>
        </div>
      </div>
      <v-card
        v-if="load"
        tile
        elevation="5"
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <v-img max-height="80" contain src="@/assets/icon/loader.gif"></v-img>
        <div class="text-body-2 opasity--text">Loading...</div>
      </v-card>
      <v-card v-else elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-sm-15">
        <v-card flat height="70vh" min-height="260" v-if="!data.result" class="pa-3 d-flex align-center justify-center flex-column">
          <v-img max-height="124" contain src="@/assets/img/notFound.svg"></v-img>
          <div class="text-center text-h5 font-weight-bold mt-8">Sorry, but nothing found</div>
        </v-card>
        <div v-else>
          <v-data-table
            hide-default-footer
            fixed-header
            :headers="headers"
            :items="data.result"
            item-key="name"
            :items-per-page="Number(itemsPerPage)"
            :height="`calc(100vh - 230px)`"
            class="p-table"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item.id" height="52" class="link" @click="openItem(item.id)">
                  <td>{{ item.name }}</td>
                  <td>{{ item.login }}</td>
                  <td>
                    <div class="status" :class="item.enabled ? 'success' : 'error'">
                      {{ item.enabled ? 'On' : 'Off' }}
                    </div>
                  </td>
                  <td class="text-right">{{ item.binds }}/{{ item.binds_max }}</td>
                </tr>
              </tbody>
            </template>
            <template v-slot:footer>
              <v-divider></v-divider>
              <v-row class="mt-2 pb-2 px-10" align="center" justify="end">
                <span class="grey--text">Rows per page:</span>
                <div class="perPageSelect">
                  <v-select
                    v-model="itemsPerPage"
                    :items="itemsPerPageArray"
                    menu-props="auto"
                    hide-details
                    single-line
                    dense
                    @change="getData"
                  ></v-select>
                </div>
                <v-btn icon color="secondary" class="mr-1" @click="formerPage">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <span class="mx-4 grey--text">{{ page }} of {{ data.pages }}</span>
                <v-btn icon color="secondary" class="ml-1" @click="nextPage">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      load: true,
      search: this.$route.query.src || '',
      itemsPerPage: Number(this.$route.query.page_size) || 10,
      page: Number(this.$route.query.page) || 1,
      headers: [
        { text: 'Name', value: 'name', sortable: false },
        { text: 'Login', value: 'login', sortable: false },
        { text: 'Enabled', value: 'enabled', sortable: false },
        { text: 'Bind(s) up/max', align: 'end', value: 'binds', sortable: false },
      ],
      statusError: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    notifi(btn) {
      if (btn == 'search400') {
        this.$notify({
          message: 'This field must be at least 3 characters, maximum 10',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == '500') {
        this.$notify({
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    clearSearch() {
      this.page = 1;
      this.search = '';
      this.getData();
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
      this.getData();
    },
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    openItem(id) {
      this.$router.push(`/providers/${id}`);
    },
    async getData() {
      await this.$store
        .dispatch('getProvidersList', {
          pageSize: this.itemsPerPage,
          search: this.search,
          page: this.page,
        })
        .catch((e) => {
          this.getDataError(e);
        })
        .finally(() => {
          this.load = false;
        });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      } else if (e.response.data.error[0] === 'result__not_found') {
        this.$store.dispatch('setProvidersList', {});
      } else if (this.statusError >= 400 && this.statusError < 500) {
        this.notifi('search400');
      } else if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.providersList;
    },
    itemsPerPageArray() {
      return this.$store.getters.pagination;
    },
  },
  destroyed() {
    this.$store.dispatch('setProvidersList', {});
  },
};
</script>

<style lang="scss" scoped>
.providers {
  .status {
    text-align: center;
    border-radius: 24px;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    width: fit-content;
    padding: 0 16px;
    color: white;
  }
  .title-input {
    max-width: 764px;
  }
}
</style>
